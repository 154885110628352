import { FC } from "react";
import { DataItemType } from "./App";
import cn from "classnames";
import { formatMoney } from "./helpers";

type DataItemProps = {
	title: string;
	data?: DataItemType;
	completed: boolean;
	onChange?: (value: string) => void;
}
  
 
const DataItem: FC<DataItemProps> = ({title, data, completed, onChange}) => {
 
  const maxLength = 5;

	return (
	  <div className="row align-items-center py-1 item">
		<div className="col-2">
		  <strong>{title}</strong>
		</div>
		<div className="col-2 text-center">{data?.prev}</div>
		<div className="col-3 input-padding">
		  {!completed ? <input 
			type="text" 
			className={cn('form-control form-control-sm', {invalid: data?.error})} 
			maxLength={maxLength}
			itemType="tel"
			pattern='[0-9]*'
			inputMode="numeric"
			value={data?.current ?? ''}
			onChange={(e) => onChange && onChange(e.target.value)}
  
		  /> : <div className="text-center">{data?.current ?? ''}</div>}
		</div>
		<div className="col-2">{!data?.error && data?.count != null ? data.count : '-'}</div>
		<div className="col-3 text-right">{!data?.error && data?.cost != null ? formatMoney(data?.cost) : '-'}</div>
	  </div>
	)
}

export default DataItem;