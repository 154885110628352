import { FC, useEffect, useMemo, useRef, useState } from "react";
import cn from 'classnames';
import { capitalize } from "./helpers";
import { DateItemType } from "./App";

type Props = {
	date: DateItemType | undefined;
	onChange: (value: DateItemType) => void;
	onClose: () => void;
}
const DatePicker: FC<Props> = ({date, onChange, onClose}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const monthList = Array.from({length: 12}, (e, i) => {
		return capitalize(new Date(0, i + 1, 0).toLocaleDateString("ru", {month: "long"}));
	});

	const [currMonth, setCurrMonth] = useState<number|null>(date?.month ? Number(date.month): null);
	const toggleCurrMonth = (month: number) => setCurrMonth(month !== currMonth ? month : null);

	const defaultYear = new Date().getFullYear();
	const yearFrom = defaultYear - 2;
	const yearTo = defaultYear + 1;

	const yearList = Array.from({ length: yearTo - yearFrom + 1 }, (_, i) => yearFrom + i);
	const [currYear, setCurrYear] = useState<number|null>(date?.year ? Number(date.year) : null);
	const toggleCurrYear = (year: number) => setCurrYear(year !== currYear ? year : null);

	const yearError = useMemo(() => !!currYear && currYear < 2000, [currYear]);

	console.log('Date: ', date);
	useEffect(() => {
		console.log('currDate: ', currMonth, currYear);
	}, [currMonth, currYear])
	
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				onClose();
			}
		}
		  // Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, onClose]);
	
	return (
		<div ref={wrapperRef} className="popover fade bs-popover-bottom show date-select">
			<div className="arrow"></div>
			
			<div className="container-fluid">
				<div className="row">
					<div className="col form-group">
						{monthList.map((month, idx) => (
							<button 
								className={cn("btn btn-sm btn-block", idx + 1 === currMonth ? 'btn-secondary': 'btn-outline-secondary')}
								key={idx}
								onClick={() => toggleCurrMonth(idx + 1)}
							>
								{month}
							</button>)
						)}
					</div>
					<div className="col">
						<div className="d-flex flex-column justify-content-between h-100">
							<div className="form-group">
								{yearList.map((year, idx) => (
									<button 
										className={cn("btn btn-sm btn-block", year === currYear ? 'btn-secondary': 'btn-outline-secondary')}
										key={idx}
										onClick={() => toggleCurrYear(year)}
									>
										{year}
									</button>)
								)}
							</div>
							<div className="form-group mt-3">
								<input 
									type="text" 
									className={cn("form-control form-control-sm", {invalid: yearError})}
									placeholder='Год...'
									maxLength={4}
									itemType="tel"
									pattern='[0-9]*'
									inputMode="numeric"
									value={currYear && !yearList.includes(currYear) ? currYear : ''}
									onChange={(e) => setCurrYear(parseInt(e.target.value))}
								/>
							</div>
							<div className="form-group mt-auto">
								<button 
									className="btn btn-primary btn-block" 
									disabled={!!!currMonth || !!!currYear || yearError}
									onClick={() => {
										!!currMonth && !!currYear && onChange({month: currMonth, year: currYear, active: date?.active ?? false});
										onClose();
									}}
								>
									Выбрать
								</button>
								<button className="btn btn-outline-primary btn-block" onClick={() => onClose()}>Отмена</button>
							</div>
						</div>
					</div>

				</div>
			</div>
  		</div>
	);
}

export default DatePicker;