import { FC, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import axios from "axios";
import DataItem from "./DataItem";
import { formatMoney } from "./helpers";
import DatePicker from "./DatePicker";

export type DateItemType = {
  month: number;
  year: number;
  active: boolean;
}

type DateListType = {
  current: DateItemType;
  prev: DateItemType;
  next: DateItemType;
}

export type DataItemType = {
  price: number;
  current: number | null;
  prev: number | null;
  error: boolean;

  count: number | null;
  cost: number | null;
}

export const defaultData = (data: DataItemType | null | undefined) => {
  return data ?? { prev: 0, current: 0, price: 0, cost: 0, count: 0, error: false} as DataItemType;
}


type DataListType = {
  waterhot: DataItemType;
  watercold: DataItemType;
  drainage: DataItemType;
  powert1: DataItemType;
  powert2: DataItemType;
  powert3: DataItemType;
};

type DataItemListType = keyof DataListType;

const App: FC = () => {
  const queryStr = window.location.search.slice(window.location.search.indexOf('?') + 1);
  const [dateList, setDateList] = useState<DateListType>({
    current: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      active: false
    }
  } as DateListType);

  const [dataList, setDataList] = useState<DataListType>();
  const [isCompleted, setCompleted] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBusy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [loadError, setLoadError] = useState<string|null>(null);
  const [showLoadError, setShowLoadError] = useState<boolean>(false);
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const titleRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState<string>('Без названия');
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [titleIsChanged, setTitleIsChanged] = useState<boolean>(false);

  const [hash, setHash] = useState<string>(() => {
    const savedHash = localStorage.getItem('hash');
    const value = queryStr || (savedHash && JSON.parse(savedHash));
    return value || null;
  })

  useEffect(() => {
    localStorage.setItem('hash', JSON.stringify(hash))
  }, [hash]);

  useEffect(() => {
    setShowLoadError(!!loadError);
  }, [loadError])

  const loadData = async (date?: string) => {
    setIsLoading(true);
    setLoadError(null);

    const url = '/api/v2' + (date ? '/' + date : '');
    try {
      const { data } = await axios.get(url + (hash ? '?' + hash : ''));

      if (typeof data !== 'object') {
        throw 'data incorrect';
      }

      if (data && data.items) {
        Object.keys(data.items).forEach(key => {
          if (data.items[key]) {
            data.items[key] = calcItem(data.items[key], data.items[key].current, key as DataItemListType, true);
          }
        })
      }

      data.date && setDateList(data.date);
      setDataList(data.items);
      setCompleted(data.completed);
      setHash(data.hash);
      data.title && setTitle(data.title);

    } catch(e) {
      console.error('Got error: ', e);
      setLoadError('Ошибка загрузки данных');
    }
    setIsLoading(false);
    
  }

  const dateUrlFormat = (date: DateItemType | undefined): string => 
    (!date && '') || String(date?.year) + ((date?.month && String(date.month)) || '')
    // (!date && '') || String(date?.year) + ((date?.month && String(date.month < 10 ? '0' + date.month : date.month)) || '')

  const showPrev = async () => {
    await loadData(dateUrlFormat(dateList?.prev));
  }

  const showNext = async () => {
    await loadData(dateUrlFormat(dateList?.next))
  }

  const dateTitleFormat = (date: DateItemType | undefined): string => 
    date && date.month && date.year ? date.month + '.' + date.year : '-'
  
  const calcItem = (dataItem: DataItemType, currentVal: number | null, key: DataItemListType, skipError?: boolean): DataItemType => {
    const countVal = currentVal != null && !isNaN(currentVal) ? currentVal - (dataItem.prev ?? 0)  : null;
    const costVal = countVal != null ? countVal * (dataItem.price ?? 0) : null;
    const error = !skipError && !((costVal != null && costVal >= 0) && (countVal != null && countVal >= 0));

    const newData = {
      ...dataItem,
      current: (currentVal && !isNaN(currentVal) && currentVal) || null,
      cost: costVal,
      count: countVal,
      error
    }

    return newData;
  }

  const handleChange = (value: string, key: DataItemListType) => {
    if (!dataList) {
      return;
    }
  
    setDataList((state) => {
      if (!state) {
        return;
      }

      const val = {
        ...state,
        [key]: calcItem(state[key], parseInt(value), key)
      }
      return val as DataListType
    })

  }

  const waterDrain = useMemo(() => {
    const drainage = {
      price: dataList?.drainage?.price || 0,
      cost: null,
      count: null,
    } as DataItemType;
    
    if (dataList && dataList.watercold && dataList.waterhot) {
      drainage.count = dataList.watercold.count != null && dataList.waterhot.count != null ? dataList.watercold.count + dataList.waterhot.count : null;
      drainage.cost = drainage.count != null && drainage.price ? drainage.count * drainage.price : null;
    }
    
    return drainage
    
  }, [dataList]);

  const waterCost = useMemo(
    () => dataList?.watercold.cost != null && dataList?.waterhot.cost != null && waterDrain.cost != null 
      ? dataList?.watercold.cost + dataList?.waterhot.cost + waterDrain.cost 
      : null, 
    [dataList, waterDrain]
  );

  const powerCost = useMemo(
    () => dataList?.powert1.cost != null && dataList?.powert2.cost != null && dataList.powert3.cost != null 
      ? dataList?.powert1.cost + dataList?.powert2.cost + dataList.powert3.cost 
      : null, 
    [dataList]
  );

  const overallCost = useMemo(
    () => waterCost != null && powerCost != null ? waterCost + powerCost : null, 
    [waterCost, powerCost]
  );

  const hasError = useMemo(() => dataList && Object.keys(dataList).some(key => dataList[key as DataItemListType].error), [dataList]);


  const saveData = async () => {
    const date = dateUrlFormat(dateList?.current);
    const reqData = dataList && Object.fromEntries(Object.entries(dataList).map(([k, v]) => [k, v.current]));
    const url = '/api/v2' + (date ? '/' + date : '');

    !!error && setError(null);
    setBusy(true);
    try {
      const { data: result }  = await axios.post(url + (hash && '?' + hash), reqData);
      if (!result.success) {
        setError('Ошибка отправки данных');
      } else {
        await loadData(date);
        setCompleted(true);
      }
    } catch(e) {
      console.warn('Got error: ', e);
      setError('Ошибка сервера');
    }
    
    
    setBusy(false);
  }

  const changeData = () => {
    setCompleted(false);
  }

  const handleChangeTitle = async () => {
    await axios.put('/api/v2/title' + (hash && '?' + hash), {title})
    setEditTitle(false);
    setTitleIsChanged(false);
  }



  useEffect(() => {
    (async () => await loadData())();
  }, []);

  useEffect(() => {
    setTitleIsChanged(true);
  }, [title]);


  return (
    <div className="container-fluid">
      
      <div className="row py-2 justify-content-center align-items-center">
        <div className="col text-right">
          
          {editTitle 
            ? (<div className="input-group">
                <input 
                  type="text" 
                  className="form-control text-right" 
                  value={title}
                  ref={titleRef}
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={(e) => { if (!e.target.value) { e.target.value = title } else handleChangeTitle() }}
                  onFocus={(e) => { if (e.target.value === title) e.target.value = '' }}
                />
                <div className="input-group-append">
                  <button 
                    className="btn btn-outline-primary" 
                    type="button"
                    disabled={!title || !titleIsChanged}
                    onClick={() => handleChangeTitle()}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </button>
                  <button 
                    className="btn btn-outline-secondary" 
                    type="button"
                    onClick={() => setEditTitle(false)}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              </div>)
            : (<h4 onClick={() => { setEditTitle(true); setTitleIsChanged(false); }}>{title}</h4>)
          }
        </div>
        <div className="col-5">
          <div className="load-button">
            <button 
              className={cn("btn btn-load", {['text-danger']: !!loadError})}
              onClick={() => loadData()}
            >
              {isLoading ? <i className="fa fa-spinner fa-pulse fa-2x fa-fw" /> : <i className="fa fa-refresh fa-2x" />}
            </button>

            {!!loadError && showLoadError && (<div className="tooltip bs-tooltip-bottom show danger" role="tooltip" onClick={() => setShowLoadError(false)}>
              <div className="arrow"></div>
              <div className="tooltip-inner">{loadError}</div>
            </div>)}
          </div>
        </div>
      </div>

      <div className="row align-items-center py-1">
        <div className="col text-center arrows left">
          <button disabled={!dateList?.prev} onClick={() => showPrev()}>
            <i className="fa fa-angle-left"></i>
          </button>
        </div>
        <div className="col text-center prev-date">
          <small>
            {dateTitleFormat(dateList?.prev)}
          </small>
        </div>
        <div className="col text-center curr-date">
          <small>
            <strong onClick={() => setDatePickerVisible(true)}>{dateTitleFormat(dateList?.current)}</strong>
          </small>
          {datePickerVisible && <DatePicker 
            date={dateList?.current} 
            onClose={() => setDatePickerVisible(false)} 
            onChange={(date) => loadData(String(date.year) + String(date.month < 10 ? '0' + date.month : date.month))} 
          />}
        </div>
        <div className={cn('col text-center next-date', {disabled: !dateList?.next?.active})}>
          <small>
            <strong>{dateTitleFormat(dateList?.next)}</strong>
          </small>
        </div>
        <div className="col text-center arrows right">
          <button disabled={!dateList?.next} onClick={() => showNext()}>
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
      </div>

      
      
      <fieldset className="row">
        <label className="col">Водоснабжение</label>
      </fieldset>
      <DataItem title="ХВС" data={dataList?.watercold} completed={isCompleted} onChange={value => handleChange(value, 'watercold')}/>
      <DataItem title="ГВС" data={dataList?.waterhot} completed={isCompleted} onChange={value => handleChange(value, 'waterhot')}/>
      <div className="row align-items-center py-1">
        <div className="col-7">
          <strong>Водоотведение</strong>
        </div>
        <div className="col-2">{waterDrain.count != null ? waterDrain.count : '-'}</div>
        <div className="col-3 text-right">{waterDrain.cost != null ? formatMoney(waterDrain.cost) : '-'}</div>
      </div>
      
      <div className="row align-items-center py-2">
        <div className="col-8 text-right">
          <strong>Итого:</strong>
        </div>
        <div className="col-4 text-right">
          {waterCost ? formatMoney(waterCost) : '-'}
        </div>
      </div>

      <fieldset className="row">
        <label className="col">Электроэнергия</label>
      </fieldset>
      <DataItem title="Т1" data={dataList?.powert1} completed={isCompleted} onChange={value => handleChange(value, 'powert1')}/>
      <DataItem title="Т2" data={dataList?.powert2} completed={isCompleted} onChange={value => handleChange(value, 'powert2')}/>
      <DataItem title="Т3" data={dataList?.powert3} completed={isCompleted} onChange={value => handleChange(value, 'powert3')}/>
      <div className="row align-items-center py-2">
        <div className="col-8 text-right">
          <strong>Итого:</strong>
        </div>
        <div className="col-4 text-right">
          {powerCost != null ? formatMoney(powerCost) : '-'}
        </div>
      </div>

      <div className="row align-items-center py-2">
        <div className="col-8 text-right">
          <strong>Общая сумма:</strong>
        </div>
        <div className="col-4 text-right">
          {overallCost != null ? formatMoney(overallCost) : '-'}
        </div>
      </div>

      <div className="row py-2">
        <div className="col text-center">
          {!isCompleted 
            ? <button 
                className="btn btn-primary btn-block" 
                onClick={() => saveData()} 
                disabled={hasError || overallCost == null || isBusy}
              >
                Сохранить
              </button>
           : <button 
                className="btn btn-outline-primary btn-block" 
                onClick={() => changeData()}
                disabled={isBusy}
              >
                Изменить
              </button>
          }
        </div>
      </div>
    </div>
  );
}

export default App;

